import { default as _91id_933TMbMYYSLkMeta } from "/tmp/8dd1bac329579ce/pages/applicant/[id].vue?macro=true";
import { default as _91id_93BRb1B94QkvMeta } from "/tmp/8dd1bac329579ce/pages/apply/[id].vue?macro=true";
import { default as _91id_93dOtEQKH5uVMeta } from "/tmp/8dd1bac329579ce/pages/apply/perfil/[id].vue?macro=true";
import { default as thanks4DNvsy63iNMeta } from "/tmp/8dd1bac329579ce/pages/apply/thanks.vue?macro=true";
import { default as _91_46_46_46slug_93TH82USzpm1Meta } from "/tmp/8dd1bac329579ce/pages/auth/password/[...slug].vue?macro=true";
import { default as forgotzEwy2H6g1GMeta } from "/tmp/8dd1bac329579ce/pages/auth/password/forgot.vue?macro=true";
import { default as _91_46_46_46slug_93FAvigqKQvOMeta } from "/tmp/8dd1bac329579ce/pages/auth/signin/[...slug].vue?macro=true";
import { default as indexOosA88u6NLMeta } from "/tmp/8dd1bac329579ce/pages/auth/signin/index.vue?macro=true";
import { default as _91email_93ZoRkrcJGr1Meta } from "/tmp/8dd1bac329579ce/pages/auth/signup/[email].vue?macro=true";
import { default as indexJrfxSNTHHHMeta } from "/tmp/8dd1bac329579ce/pages/auth/signup/index.vue?macro=true";
import { default as _91_46_46_46slug_93XUZWS4K1EBMeta } from "/tmp/8dd1bac329579ce/pages/auth/verifyemail/[...slug].vue?macro=true";
import { default as jobs5quD8pJS8yMeta } from "/tmp/8dd1bac329579ce/pages/company/[id]/jobs.vue?macro=true";
import { default as indexL0aJSLEPePMeta } from "/tmp/8dd1bac329579ce/pages/company/index.vue?macro=true";
import { default as onboardinge8fwYXK9SrMeta } from "/tmp/8dd1bac329579ce/pages/company/onboarding.vue?macro=true";
import { default as index63GTqfr4viMeta } from "/tmp/8dd1bac329579ce/pages/index.vue?macro=true";
import { default as _91applicant_93SUxjbPmiw4Meta } from "/tmp/8dd1bac329579ce/pages/job/[job]/applicants/[applicant].vue?macro=true";
import { default as indexsYo5d5fLaJMeta } from "/tmp/8dd1bac329579ce/pages/job/[job]/applicants/index.vue?macro=true";
import { default as duplicateuC7fdnkjizMeta } from "/tmp/8dd1bac329579ce/pages/job/[job]/duplicate.vue?macro=true";
import { default as indexNznikcqJqPMeta } from "/tmp/8dd1bac329579ce/pages/job/[job]/index.vue?macro=true";
import { default as archivedVTWl4BqacXMeta } from "/tmp/8dd1bac329579ce/pages/job/archived.vue?macro=true";
import { default as closed30JOPljrSnMeta } from "/tmp/8dd1bac329579ce/pages/job/closed.vue?macro=true";
import { default as new3x55hxDWL4Meta } from "/tmp/8dd1bac329579ce/pages/job/new.vue?macro=true";
import { default as openedEA3l50h7gqMeta } from "/tmp/8dd1bac329579ce/pages/job/opened.vue?macro=true";
import { default as paused2ZjpScGCXkMeta } from "/tmp/8dd1bac329579ce/pages/job/paused.vue?macro=true";
import { default as indexZeICXa6nbnMeta } from "/tmp/8dd1bac329579ce/pages/plans/index.vue?macro=true";
import { default as settingsBSlYNeQS9CMeta } from "/tmp/8dd1bac329579ce/pages/settings.vue?macro=true";
import { default as indexG0XDnyJlCkMeta } from "/tmp/8dd1bac329579ce/pages/team/index.vue?macro=true";
import { default as index5ESNttgdOdMeta } from "/tmp/8dd1bac329579ce/pages/thanks/index.vue?macro=true";
import { default as indexdZ7k8cGkO9Meta } from "/tmp/8dd1bac329579ce/pages/user/index.vue?macro=true";
export default [
  {
    name: "applicant-id",
    path: "/applicant/:id()",
    meta: _91id_933TMbMYYSLkMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/applicant/[id].vue")
  },
  {
    name: "apply-id",
    path: "/apply/:id()",
    component: () => import("/tmp/8dd1bac329579ce/pages/apply/[id].vue")
  },
  {
    name: "apply-perfil-id",
    path: "/apply/perfil/:id()",
    component: () => import("/tmp/8dd1bac329579ce/pages/apply/perfil/[id].vue")
  },
  {
    name: "apply-thanks",
    path: "/apply/thanks",
    component: () => import("/tmp/8dd1bac329579ce/pages/apply/thanks.vue")
  },
  {
    name: "auth-password-slug",
    path: "/auth/password/:slug(.*)*",
    component: () => import("/tmp/8dd1bac329579ce/pages/auth/password/[...slug].vue")
  },
  {
    name: "auth-password-forgot",
    path: "/auth/password/forgot",
    component: () => import("/tmp/8dd1bac329579ce/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-signin-slug",
    path: "/auth/signin/:slug(.*)*",
    meta: _91_46_46_46slug_93FAvigqKQvOMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/auth/signin/[...slug].vue")
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    meta: indexOosA88u6NLMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/auth/signin/index.vue")
  },
  {
    name: "auth-signup-email",
    path: "/auth/signup/:email()",
    component: () => import("/tmp/8dd1bac329579ce/pages/auth/signup/[email].vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: indexJrfxSNTHHHMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/auth/signup/index.vue")
  },
  {
    name: "auth-verifyemail-slug",
    path: "/auth/verifyemail/:slug(.*)*",
    component: () => import("/tmp/8dd1bac329579ce/pages/auth/verifyemail/[...slug].vue")
  },
  {
    name: "company-id-jobs",
    path: "/company/:id()/jobs",
    component: () => import("/tmp/8dd1bac329579ce/pages/company/[id]/jobs.vue")
  },
  {
    name: "company",
    path: "/company",
    meta: indexL0aJSLEPePMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/company/index.vue")
  },
  {
    name: "company-onboarding",
    path: "/company/onboarding",
    meta: onboardinge8fwYXK9SrMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/company/onboarding.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index63GTqfr4viMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/index.vue")
  },
  {
    name: "job-job-applicants-applicant",
    path: "/job/:job()/applicants/:applicant()",
    meta: _91applicant_93SUxjbPmiw4Meta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/job/[job]/applicants/[applicant].vue")
  },
  {
    name: "job-job-applicants",
    path: "/job/:job()/applicants",
    meta: indexsYo5d5fLaJMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/job/[job]/applicants/index.vue")
  },
  {
    name: "job-job-duplicate",
    path: "/job/:job()/duplicate",
    meta: duplicateuC7fdnkjizMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/job/[job]/duplicate.vue")
  },
  {
    name: "job-job",
    path: "/job/:job()",
    meta: indexNznikcqJqPMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/job/[job]/index.vue")
  },
  {
    name: "job-archived",
    path: "/job/archived",
    meta: archivedVTWl4BqacXMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/job/archived.vue")
  },
  {
    name: "job-closed",
    path: "/job/closed",
    meta: closed30JOPljrSnMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/job/closed.vue")
  },
  {
    name: "job-new",
    path: "/job/new",
    meta: new3x55hxDWL4Meta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/job/new.vue")
  },
  {
    name: "job-opened",
    path: "/job/opened",
    meta: openedEA3l50h7gqMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/job/opened.vue")
  },
  {
    name: "job-paused",
    path: "/job/paused",
    meta: paused2ZjpScGCXkMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/job/paused.vue")
  },
  {
    name: "plans",
    path: "/plans",
    meta: indexZeICXa6nbnMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/plans/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsBSlYNeQS9CMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/settings.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: indexG0XDnyJlCkMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/team/index.vue")
  },
  {
    name: "thanks",
    path: "/thanks",
    meta: index5ESNttgdOdMeta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/thanks/index.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: indexdZ7k8cGkO9Meta || {},
    component: () => import("/tmp/8dd1bac329579ce/pages/user/index.vue")
  }
]